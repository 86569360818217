import clsx from 'clsx'
import { useState } from 'react'
import {
    AccountRecord,
    ClassifierDetails,
    useCreateModerationBoundaryMutation,
} from '../../api/client'
import {
    BoundaryType,
    CATEGORICAL_BOUNDARIES,
    NUMERICAL_BOUNDARIES,
    Queue,
    SeverityCategory,
    severityCategoryStringToEnum,
} from '../../models/Moderation'
import Button from '../Button'
import ArwenListBox, { ArwenListBoxItem } from '../inputs/ArwenListBox'
import SimpleLoader from '../SimpleLoader'
import { toastError, toastSuccess } from '../Notification'

type Props = {
    account: AccountRecord
    classifierDetailsList: ClassifierDetails[]
    queue: Queue
    onCreate: () => void
}

export default function NewModerationBoundary(props: Props) {
    const { account, queue, classifierDetailsList, onCreate } = props
    const [selectedItem, setSelectedItem] = useState<ArwenListBoxItem>()
    const [probability, setProbability] = useState<number>(0.5)
    const [category, setCategory] = useState<SeverityCategory>(
        SeverityCategory.HIGH
    )

    const [
        createModerationBoundary,
        { loading: createModerationBoundaryLoading },
    ] = useCreateModerationBoundaryMutation({
        onError: () => {
            toastError('Failed to create moderation boundary')
        },
        onCompleted: () => {
            toastSuccess('Moderation boundary created')
        },
    })

    const listBoxItems: ArwenListBoxItem[] = classifierDetailsList.map(
        (classifier) => {
            return {
                id: `${classifier.id}` || '',
                name: `${classifier.displayName} (${classifier.shortName})`,
            }
        }
    )

    const selectedClassifier = classifierDetailsList.find(
        (item) => `${item.id}` === selectedItem?.id
    )

    const isCategorical =
        CATEGORICAL_BOUNDARIES.findIndex(
            (boundary) => boundary === selectedClassifier?.shortName
        ) > 0

    const isNumerical =
        NUMERICAL_BOUNDARIES.findIndex(
            (boundary) => boundary === selectedClassifier?.shortName
        ) > 0

    return (
        <div className={clsx('flex flex-col mt-4 flex-wrap')}>
            <div>Create new moderation boundary</div>
            <div
                className={clsx(
                    'flex flex-row flex-justify',
                    'text-gray-950',
                    'mt-3'
                )}
            >
                <div className="mr-3">
                    <ArwenListBox
                        value={
                            selectedItem || {
                                id: '',
                                name: 'Select a classifier...',
                                icon: undefined,
                            }
                        }
                        options={listBoxItems}
                        onSelect={setSelectedItem}
                        widthClass="w-90"
                    />
                </div>

                {isNumerical && (
                    <input
                        type="number"
                        name="probability"
                        id="probability"
                        className="w-24 shadow-sm focus:ring-primary-500 focus:border-primary block sm:text-sm border-gray-300 rounded-md mr-3"
                        value={probability}
                        step="0.05"
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            setProbability(
                                Number.parseFloat(event.target.value)
                            )
                        }}
                    />
                )}
                {isCategorical && (
                    <div className="mr-3">
                        <ArwenListBox
                            value={{ id: category, name: category }}
                            options={[
                                {
                                    id: SeverityCategory.EXTREME,
                                    name: SeverityCategory.EXTREME,
                                },
                                {
                                    id: SeverityCategory.HIGH,
                                    name: SeverityCategory.HIGH,
                                },
                                {
                                    id: SeverityCategory.MEDIUM,
                                    name: SeverityCategory.MEDIUM,
                                },
                                {
                                    id: SeverityCategory.LOW,
                                    name: SeverityCategory.LOW,
                                },
                                {
                                    id: SeverityCategory.NONE,
                                    name: SeverityCategory.NONE,
                                },
                                {
                                    id: SeverityCategory.NOTDETECTED,
                                    name: SeverityCategory.NOTDETECTED,
                                },
                            ]}
                            onSelect={(selectedCategory) => {
                                setCategory(
                                    severityCategoryStringToEnum(
                                        selectedCategory.name
                                    ) || SeverityCategory.HIGH
                                )
                            }}
                            widthClass="w-90"
                        />
                    </div>
                )}
                {!createModerationBoundaryLoading && (
                    <Button
                        primary={true}
                        text={'Create'}
                        onClick={async () => {
                            if (selectedClassifier?.id) {
                                await createModerationBoundary({
                                    variables: {
                                        moderationBoundary: {
                                            accountId: account.id,
                                            classifierId: selectedClassifier.id,
                                            queue: queue,
                                            type: isCategorical
                                                ? BoundaryType.CATEGORICAL
                                                : BoundaryType.NUMERICAL,
                                            probability: isNumerical
                                                ? probability
                                                : null,
                                            severityCategory: isCategorical
                                                ? category
                                                : null,
                                        },
                                    },
                                })
                                onCreate()
                            }
                        }}
                    />
                )}
                <SimpleLoader loading={createModerationBoundaryLoading} />
            </div>
        </div>
    )
}
