import { useState, useEffect, useMemo } from 'react'
import { TeamView, useGetTeamsQuery } from '../../../api/client'
import FilterInput, { FilterItem } from './FilterInput'

export enum TeamFilterInitialSelection {
    All = 'All',
    None = 'None',
}

type Props = {
    teamIds: Array<number>
    onUpdateSelectedTeams: (accountIds: Array<number>) => void
    initialSelection?: TeamFilterInitialSelection
}

export default function TeamFilter(props: Props) {
    const { teamIds, onUpdateSelectedTeams, initialSelection } = props
    const [searchTerm, setSearchTerm] = useState('')
    const [hasInitialSelectionRun, setHasInitialSelectionRun] = useState(false)

    // Fetch the initial batch of data
    // NOTE: we do not paginate this data! Over time we will probably need to do so.
    const { data, loading } = useGetTeamsQuery({
        onError: (error: any) => {
            throw Error(error.message)
        },
    })

    // Memoize teams to avoid unnecessary re-renders
    const teams = useMemo(() => data?.getTeams || [], [data?.getTeams])

    // Handle initial selection once data is loaded
    useEffect(() => {
        if (
            !loading &&
            teams.length > 0 &&
            teamIds.length === 0 &&
            initialSelection &&
            !hasInitialSelectionRun
        ) {
            if (initialSelection === TeamFilterInitialSelection.All) {
                onUpdateSelectedTeams(teams.map((t) => t.id))
            } else if (initialSelection === TeamFilterInitialSelection.None) {
                onUpdateSelectedTeams([])
            }
            setHasInitialSelectionRun(true)
        }
    }, [
        loading,
        teams,
        teamIds,
        initialSelection,
        onUpdateSelectedTeams,
        hasInitialSelectionRun,
    ])

    function handleUpdateSelectedTeams(selectedItems: Array<FilterItem>) {
        onUpdateSelectedTeams(selectedItems.map((item) => item.id))
    }

    return (
        <>
            <FilterInput
                filterTypeName="Teams"
                searchTerm={searchTerm}
                onSearchTermChange={(term) => setSearchTerm(term)}
                onUpdateSelectedItems={handleUpdateSelectedTeams}
                selectedItems={teams
                    .filter((team) => teamIds.includes(team.id))
                    .map(mapTeamToFilterItem)}
                searchResults={teams
                    .filter((team) =>
                        (team.name || '')
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                    )
                    .map(mapTeamToFilterItem)}
                searching={loading}
            />
        </>
    )
}

function mapTeamToFilterItem(team: TeamView): FilterItem {
    return {
        id: team.id,
        name: team.name || '',
        displayName: team.name || '',
    }
}
