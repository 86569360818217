import { Field, Label, Textarea } from '@headlessui/react'
import clsx from 'clsx'
import Button from '../Button'
import { object, string } from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import FormFieldError from '../errors/FormFieldError'
import PromptTestResult from './PromptTestResult'
import SimpleLoader from '../SimpleLoader'

const PROMPT_MESSAGE_SUBSTITUTION_VARIABLE_REGEX = /<<<PLACEHOLDER MESSAGE>>>/

type Props = {
    category: string
    prompt: string
    saving: boolean
    testing: boolean
    testResult?: string[] | number
    onSave: (category: string, prompt: string) => void
    onTest: (prompt: string, message: string) => void
    onCancel?: () => void
    numberOfSuggestions?: number
    setNumberOfSuggestions?: (suggestions: number) => void
}

interface FormData {
    category: string
    prompt: string
    message?: string
}

const validationPromptSchema = object({
    category: string().required('Please enter a prompt name'),
    prompt: string()
        .required('Please enter a prompt')
        .matches(
            PROMPT_MESSAGE_SUBSTITUTION_VARIABLE_REGEX,
            'Prompt must include the substitution variable <<<PLACEHOLDER MESSAGE>>>'
        ),
    // Don't define the validation here - we don't want this on a normal form submit, just when we click test.
    message: string(),
})

export default function PromptDetailsForm(props: Props) {
    const { category, prompt, testing, testResult, onSave, onTest, onCancel } =
        props

    const promptForm = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationPromptSchema),
        defaultValues: {
            category: category,
            prompt: prompt,
            message: ``,
        },
    })

    const handleOnSubmitPrompt = async (data: FormData) => {
        onSave(data.category, data.prompt)
    }

    const handleOnTest = async () => {
        const prompt = promptForm.getValues().prompt
        const message = promptForm.getValues().message
        // This is where we define form validation for the test message.
        if (!message || message === '') {
            promptForm.setError('message', {
                message: 'Please enter a message for testing.',
            })
        } else {
            onTest(prompt, message)
        }
    }

    return (
        <div>
            <FormProvider {...promptForm}>
                <form onSubmit={promptForm.handleSubmit(handleOnSubmitPrompt)}>
                    <Field className="mt-6">
                        <Label className="block font-medium leading-6 text-gray-90">
                            Prompt name
                        </Label>

                        <Textarea
                            {...promptForm.register('category')}
                            className={clsx(
                                'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm',
                                'ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 ',
                                'focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6'
                            )}
                            rows={1}
                        />
                        {promptForm.formState.errors.category && (
                            <FormFieldError
                                message={
                                    promptForm.formState.errors.category
                                        .message || ''
                                }
                            />
                        )}
                    </Field>
                    <Field className="mt-6">
                        <Label className="block font-medium leading-6 text-gray-90">
                            Prompt
                        </Label>

                        <Textarea
                            {...promptForm.register('prompt')}
                            className={clsx(
                                'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm',
                                'ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 ',
                                'focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6'
                            )}
                            rows={10}
                        />
                        {promptForm.formState.errors.prompt && (
                            <FormFieldError
                                message={
                                    promptForm.formState.errors.prompt
                                        .message || ''
                                }
                            />
                        )}
                    </Field>
                    <div>
                        <Button
                            primary={true}
                            text="Save"
                            type="submit"
                            disabled={!promptForm.formState.isDirty}
                        ></Button>
                        <button
                            onClick={() =>
                                onCancel ? onCancel() : promptForm.reset()
                            }
                            className="py-2 px-6 underline self-end"
                            disabled={!promptForm.formState.isDirty}
                        >
                            Cancel
                        </button>
                    </div>

                    <Field className="mt-10">
                        <Label className="block font-medium leading-6 text-gray-90">
                            Test script
                        </Label>

                        <Textarea
                            {...promptForm.register('message')}
                            className={clsx(
                                'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm',
                                'ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 ',
                                'focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6'
                            )}
                            rows={4}
                        />
                        {promptForm.formState.errors.message && (
                            <FormFieldError
                                message={
                                    promptForm.formState.errors.message
                                        .message || ''
                                }
                            />
                        )}
                    </Field>
                    {props.setNumberOfSuggestions ? (
                        <Field className="mt-10">
                            <Label className="block font-medium leading-6 text-gray-90">
                                Number of suggestions
                            </Label>
                            <input
                                type="number"
                                name="number of suggestions"
                                id="number of suggestions"
                                className="w-full shadow-sm focus:ring-primary-500 focus:border-primary block sm:text-sm border-gray-300 rounded-md mt-5"
                                value={
                                    props.numberOfSuggestions
                                        ? props.numberOfSuggestions
                                        : 1
                                }
                                step="1"
                                max={5}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    props.setNumberOfSuggestions
                                        ? props.setNumberOfSuggestions(
                                              parseInt(event.target.value)
                                          )
                                        : console.log(
                                              'Set number of suggestions not defined - this should never happen.'
                                          )
                                }}
                            />
                        </Field>
                    ) : null}
                    <div className="mt-2">
                        <Button
                            primary={true}
                            text="Test"
                            onClick={handleOnTest}
                        ></Button>
                    </div>
                    {testing && <SimpleLoader loading={true} />}

                    {!testing && testResult !== undefined && (
                        <PromptTestResult result={testResult} />
                    )}
                </form>
            </FormProvider>
        </div>
    )
}
