import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { selectedAccountState } from '../../store/UIStore'
import { useGetAccountQuery } from '../../api/client'

function AuthorisationFailurePage() {
    const history = useNavigate()
    let { accountId: accountIdString } = useParams()
    const accountId = Number(accountIdString)

    const setSelectedAccount = useSetRecoilState(selectedAccountState)

    const { data } = useGetAccountQuery({
        variables: { accountId },
    })

    useEffect(() => {
        if (data?.getAccount) {
            setSelectedAccount(data?.getAccount)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const reauthorisePathFacebook =
        '/account/new/facebook_page?reauthorise=true'
    const reauthorisePathInstagram = '/account/new/instagram?reauthorise=true'
    const reauthorisePathTikTok = '/account/new/tiktok?reauthorise=true'
    const reauthorisePathTwitter = `/authorise-request/twitter/${accountId}?reauthorise=true`
    const reauthorisePathYouTube = `/authorise-youtube-verify/${accountId}?reauthorise=true`
    const socialName = data?.getAccount?.socialMediaServiceDisplayName

    let reauthorisePath = ''
    switch (data?.getAccount?.socialMediaServiceShortName?.toLowerCase()) {
        case 'youtube':
            reauthorisePath = reauthorisePathYouTube
            break
        case 'twitter':
            reauthorisePath = reauthorisePathTwitter
            break
        case 'facebook':
            reauthorisePath = reauthorisePathFacebook
            break
        case 'instagram':
            reauthorisePath = reauthorisePathInstagram
            break
        case 'tiktok':
            reauthorisePath = reauthorisePathTikTok
            break
        default:
            console.error(
                'Trying to render the authorisation failure page but we have an unknown social media service!'
            )
    }
    return (
        <div className="m-10 prose prose-stone prose-lg">
            <div className="bg-white">
                <div className="mx-auto max-w-7xl px-4 sm:px-6  lg:px-8 pt-2 pb-10">
                    <h2>
                        There was an authorisation problem with your{' '}
                        {socialName} account!
                    </h2>
                    <p>
                        This could happen when you try to manually delete or
                        hide content, and when we asked {socialName} to do this
                        on your behalf they said no.
                    </p>
                    <p>
                        This might mean Arwen is no longer protecting your
                        account.{' '}
                    </p>
                    <p>
                        This can occur when you change your password,
                        deauthorise Arwen or our permission expires. You can
                        reauthorise by clicking the button below.
                    </p>
                    <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base  text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:text-sm"
                        onClick={() => history(reauthorisePath)}
                    >
                        Re-authorise
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AuthorisationFailurePage
