export type Queue = 'SAFE' | 'SUSPECT' | 'SEVERE'

export enum BoundaryType {
    NUMERICAL = 'NUMERICAL',
    CATEGORICAL = 'CATEGORICAL',
}

export enum SeverityCategory {
    EXTREME = 'EXTREME',
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
    NONE = 'NONE',
    NOTDETECTED = 'NOTDETECTED',
}

export function severityCategoryStringToEnum(
    value: string
): SeverityCategory | undefined {
    if (Object.values(SeverityCategory).includes(value as SeverityCategory)) {
        return value as SeverityCategory
    }
    return undefined // Return undefined if the value is not in the enum
}

export type ModerationBoundaryTemplate = {
    queue: Queue
    classifierName: string
    type: BoundaryType
    probability?: number
    severityCategory?: SeverityCategory
}

/**
 * Frustratingly this information is not stored in the ClassifierDetails. It should be but I'm not changing that here.
 */
export const CATEGORICAL_BOUNDARIES = [
    'SPECTRUM_hate-speech',
    'SPECTRUM_self-harm',
    'SPECTRUM_bullying',
    'tisane_bigotry',
    'tisane_personal_attack',
    'tisane_sexual_advances',
    'tisane_criminal_activity',
    'tisane_external_contact',
    'tisane_adult_only',
    'tisane_mental_issues',
    'tisane_spam',
    'tisane_generic',
    'tisane_profanity',
]

export const NUMERICAL_BOUNDARIES = [
    'IMAGEPORN',
    'IMAGEEXTREMISM',
    'IMAGEGAMBLING',
    'IMAGEDRUGS',
    'IMAGEOFFENSIVEGESTURES',
    'IMAGEGORE',
    'IMAGESWIMUNDERWEAR',
    'IMAGEALCOHOL',
    'IMAGEWEAPONS',
    'IMAGEDOCUMENT',
    'IMAGEIDCREDITCARD',
    'IDENTITY_ATTACK',
    'INSULT',
    'TOXICITY',
    'SEVERE_TOXICITY',
    'SEXUALLY_EXPLICIT',
    'FLIRTATION',
    'PROFANITY',
    'THREAT',
    'SPECTRUM_profanity',
    'SPECTRUM_insult',
    'SPECTRUM_severe-toxic',
    'SPECTRUM_sexual',
    'SENTIMENT',
    'BLOCKED_KEYWORDS',
    'KEYWORD_RACIST',
    'URL_REGEX',
    'SPAM',
    'shadowban',
    'llm_01',
    'llm_02',
    'llm_03',
    'llm_04',
    'llm_05',
    'llm_06',
    'llm_07',
    'llm_08',
    'llm_09',
    'llm_10',

    // DC: following are not used as of 29 November 2024
    'IDENTITY_ATTACK/ABILITY',
    'IDENTITY_ATTACK/AGE',
    'IDENTITY_ATTACK/ETHNICITY',
    'IDENTITY_ATTACK/GENDER',
    'IDENTITY_ATTACK/POLITICAL_GROUP',
    'IDENTITY_ATTACK/RELIGION',
    'IDENTITY_ATTACK/SEXUAL_ORIENTATION',
    'PHYSICAL_VIOLENCE',
    'SELF_HARM',
    'SEXUAL_AGGRESSION',
    'WHITE_SUPREMACIST_EXTREMISM',
    'ILLICIT_DRUGS',
    'NLI/THREAT',
]
