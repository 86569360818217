import { SUPPORT_EMAIL } from '../../store/UIStore'
import { CheckCircleIcon } from '@heroicons/react/24/outline'

function AddInstagramErrorPage() {
    return (
        <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
            <div className="max-w-max mx-auto">
                <main className="sm:flex">
                    <p className="text-4xl font-extrabold text-red-700 sm:text-5xl">
                        Error
                    </p>
                    <div className="sm:ml-6">
                        <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                            <h1 className="text-4xl font-extrabold text-gray-950 tracking-tight sm:text-5xl">
                                Failed to add your Instagram account.
                            </h1>
                            <p className="mt-6 text-lg text-gray-500">
                                <p className="text-2xl text-gray-500">
                                    Please check that the following statements
                                    are true
                                </p>
                                <ul>
                                    <li className="flex flex-row align-baseline mt-6">
                                        <CheckCircleIcon className="h-7 w-7 mr-2" />
                                        <span>
                                            The Instagram account you selected
                                            is a Business account
                                        </span>
                                    </li>
                                    <li className="flex flex-row align-baseline mt-3">
                                        <CheckCircleIcon className="h-7 w-7 mr-2" />
                                        The Facebook page is linked to your
                                        Instagram account
                                    </li>
                                    <li className="flex flex-row align-baseline mt-3">
                                        <CheckCircleIcon className="h-7 w-7 mr-2" />
                                        If you have multiple pages and Instagram
                                        accounts please make sure you selected
                                        the correct combination
                                    </li>
                                </ul>
                                <p className="text-2xl text-gray-500 mt-6">
                                    If none of this works then please refer to{' '}
                                    <a
                                        href="https://knowledge.arwen.ai/how-to-connect-primary-to-your-instagram-account"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <span className="text-primary-600 underline">
                                            our support pages
                                        </span>
                                    </a>{' '}
                                    for further assistance.
                                </p>
                            </p>
                        </div>
                        <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                            <div className="inline-flex rounded-md shadow">
                                <a href="/">
                                    <span className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base  rounded-md text-white bg-primary-600 hover:bg-primary-700">
                                        {' '}
                                        Try again
                                    </span>
                                </a>
                            </div>
                            <div className="ml-3 inline-flex">
                                <a
                                    href={`mailto:${SUPPORT_EMAIL}`}
                                    className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base  rounded-md text-primary-700 bg-primary-100 hover:bg-primary-200"
                                    rel="noreferrer"
                                >
                                    Contact Customer Support
                                </a>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default AddInstagramErrorPage
