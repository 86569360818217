import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { bool, object, string } from 'yup'
import {
    AccountRecord,
    CreateAccountMutation,
    useCreateAccountMutation,
} from '../../api/client'
import Button from '../../components/Button'
import { toastError, toastSuccess } from '../../components/Notification'
import SimpleLoader from '../../components/SimpleLoader'
import AcceptYouTubeTerms from '../../components/authorise/AcceptYouTubeTerms'
import YouTubeAuthorisationButton from '../../components/authorise/platforms/YouTubeAuthorisationButton'
import YouTubeChannelIDHelpDisclosure from '../../components/help/YouTubeChannelIDHelpDialog'
import YouTubeChannelIDInput from '../../components/inputs/YouTubeChannelIDInput'
import { TextPageContainer } from '../../containers/TextPageContainer'
import { TextPageHeader } from '../../containers/TextPageHeader'
import {
    selectedAccountState,
    shouldRefetchAccountsState,
} from '../../store/UIStore'
import mapFromCreateAccountMutationToAccountRecord from '../../models/AccountRecord.mapper'

interface FormData {
    channelId: string
    acceptTermsCheckbox: boolean
    // The following fields are not part of the visual form. They are, rather, all the fields
    // that are required (2025-05) to create an account using our mutation. They come from the
    // username lookup.
    name: string
    serviceAccountId: string
    username: string
}

/**
 * The yup validation schema aligns with the FormData interface, and with the forms `defaultValues`.
 */
const validationSchema = object({
    channelId: string()
        .required('Channel ID is required')
        .min(4, 'Channel ID must be at least 4 characters'),
    acceptTermsCheckbox: bool()
        .required('You must accept the terms and conditions.')
        .oneOf([true], 'You must accept the terms and conditions.'),

    name: string().required('Name is required'),
    serviceAccountId: string().required('Service Account ID is required'),
    username: string().required('Username is required'),
})

/**
 * This page is used to add a YouTube account to Arwen.
 */
function NewYouTubeAccountPage() {
    const setSelectedAccount = useSetRecoilState(selectedAccountState)

    const [account, setAccount] = useState<AccountRecord | null>()

    const form = useForm<FormData>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            acceptTermsCheckbox: false,
            channelId: '',
            name: '',
            serviceAccountId: '',
            username: '',
        },
    })

    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const [_shouldRefetchAccounts, setShouldRefetchAccounts] = useRecoilState(
        shouldRefetchAccountsState
    )

    const [createAccountMutation, { loading: authSaveLoading }] =
        useCreateAccountMutation({
            onCompleted: (account: CreateAccountMutation) => {
                if (account.createAccount) {
                    let newAccount =
                        mapFromCreateAccountMutationToAccountRecord(
                            account.createAccount
                        )
                    setSelectedAccount(newAccount)
                    setAccount(newAccount)
                } else {
                    console.error(
                        'We created an account but did not get an account back from the server!'
                    )
                }
                setShouldRefetchAccounts(true)
                toastSuccess(
                    `YouTube account ${account?.createAccount?.accountName} added`
                )
            },
            onError: (error) => {
                toastError(error.message)
            },
        })

    const onSubmit = async (data: FormData) => {
        createAccountMutation({
            variables: {
                serviceAccountId: data.channelId,
                accountName: data.name,
                serviceAccountUsername: data.username,
                serviceName: 'youtube',
            },
        })
    }

    let component
    if (account) {
        component = (
            <TextPageContainer>
                <TextPageHeader title="One more step..." />
                <p>
                    Authorise your account so we can start moderating your
                    comments.
                </p>
                <YouTubeAuthorisationButton account={account} />
            </TextPageContainer>
        )
    } else {
        // TODO: The Add channel button needs a spinner on the actual button, not half off the page below.
        component = (
            <TextPageContainer>
                <TextPageHeader title="Add YouTube channel" />
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <p>
                            Arwen needs your permission to moderate your
                            comments.
                        </p>

                        <div className="mt-6 sm:mt-0 flex flex-col">
                            <label className="block">
                                Please enter your YouTube Channel ID below{' '}
                            </label>
                            <YouTubeChannelIDHelpDisclosure />
                            <YouTubeChannelIDInput />
                            <AcceptYouTubeTerms />
                            <div className="mt-6">
                                <Button
                                    disabled={
                                        !form.formState.isValid ||
                                        form.formState.isSubmitting ||
                                        (form.formState.dirtyFields.channelId &&
                                            form.getValues('channelId') === '')
                                    }
                                    primary={true}
                                    text="Add channel"
                                    type="submit"
                                    size="large"
                                />
                            </div>
                        </div>
                    </form>
                </FormProvider>
                <SimpleLoader loading={authSaveLoading} />
            </TextPageContainer>
        )
    }
    return component
}

export default NewYouTubeAccountPage
