import FacebookAuthorisationButton from '../../components/authorise/platforms/FacebookAuthorisationButton'
import { TextPageContainer } from '../../containers/TextPageContainer'
import { TextPageHeader } from '../../containers/TextPageHeader'

/**
 * This page is used to add a Facebook account to Arwen.
 */
function NewFacebookAccountPage() {
    return (
        <TextPageContainer>
            <TextPageHeader title="Add a Facebook Page to Arwen" />
            <p className="mb-4">Click below to add a Facebook Page to Arwen.</p>
            <FacebookAuthorisationButton />
        </TextPageContainer>
    )
}

export default NewFacebookAccountPage
