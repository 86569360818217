import { Link, useNavigate, useParams } from 'react-router-dom'
import ErrorMessageContainer from '../../../components/errors/ErrorMessageContainer'
import PromptDetailsForm from '../../../components/prompt/PromptDetailsForm'
import { TextPageContainer } from '../../../containers/TextPageContainer'
import { TextPageHeader } from '../../../containers/TextPageHeader'
import SimpleLoader from '../../../components/SimpleLoader'
import { useTitle } from '../../../components/TitleProvider'
import {
    useGetLlmClassificationPromptQuery,
    useTestLlmClassificationPromptLazyQuery,
    useUpdateLlmClassificationPromptMutation,
} from '../../../api/client'
import { toastError, toastSuccess } from '../../../components/Notification'

type ParamTypes = {
    accountId: string
    promptId: string
}

type Props = {
    category: 'classify' | 'reply'
}

export default function PromptEditPage(props: Props) {
    useTitle('Edit LLM Prompt')
    const navigate = useNavigate()

    // Which prompt are we editing?
    const { accountId: accountIdString, promptId: promptIdString } =
        useParams<ParamTypes>()

    const accountId = Number(accountIdString)
    const promptId = Number(promptIdString)
    const {
        data: promptData,
        loading: promptLoading,
        error: promptError,
    } = useGetLlmClassificationPromptQuery({
        variables: {
            promptId,
        },
        onError: (error) => {
            toastError(
                `Failed to load LLM classification prompt: ${error.message}`
            )
        },
    })

    const [updateLlmClassificationPrompt, { loading: updateLoading }] =
        useUpdateLlmClassificationPromptMutation({
            onError: (error) => toastError(error.message),
        })

    const [
        testLlmClassificationPrompt,
        { data: testClassificationData, loading: testResultLoading },
    ] = useTestLlmClassificationPromptLazyQuery({
        onError: (error) => {
            toastError(
                `Failed to test LLM classification prompt: ${error.message}`
            )
        },
    })

    async function updateLlm(category: string, prompt: string) {
        await updateLlmClassificationPrompt({
            variables: {
                category: category,
                prompt: prompt,
                promptId: promptId,
            },
            onCompleted: () => {
                toastSuccess('Prompt updated')
                navigate(`/account/${accountId}/settings#llm-settings`)
            },
        })
    }

    const handleOnTest = async (prompt: string, message: string) => {
        await testLlmClassificationPrompt({
            variables: {
                prompt: prompt,
                testMessage: message,
            },
        })
    }

    // Display a full page error if something goes wrong. NB this isn't the full screen error shown by the MainErrorBoundary
    if (promptError) {
        return <ErrorMessageContainer message={promptError.message} />
    }

    const prompt = promptData?.getLlmClassificationPrompt

    return (
        <TextPageContainer>
            <TextPageHeader title="Edit classification prompt" />
            <Link to={`/account/${accountId}/settings#llm-settings`}>
                Back to settings
            </Link>

            {(promptLoading || updateLoading || testResultLoading) && (
                <SimpleLoader loading={true} />
            )}
            {prompt && (
                <div className="flex flex-col mb-32 mt-10">
                    <div className="w-full ml-3">
                        <PromptDetailsForm
                            category={prompt.category || ''}
                            prompt={prompt.prompt}
                            saving={false}
                            testing={false}
                            onSave={updateLlm}
                            onTest={handleOnTest}
                            onCancel={() =>
                                navigate(
                                    `/account/${accountId}/settings#llm-settings`
                                )
                            }
                            testResult={
                                testClassificationData?.testLlmClassificationPrompt
                            }
                        />
                    </div>
                </div>
            )}
        </TextPageContainer>
    )
}
