import {
    DialogPanel,
    DialogTitle,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    Transition,
} from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid'
import { Fragment, useState } from 'react'
import {
    AccountRecord,
    ClassifierDetails,
    useBulkUpdateModerationBoundaryMutation,
} from '../../api/client'
import SimpleLoader from '../SimpleLoader'
import SimpleModal from '../SimpleModal'
import { createClassifierDetailsMap } from './classifierDetailsMapper'
import { mapTemplateValue } from './moderationBoundaryMapper'
import { Queue } from './ModerationContent'
import getModerationTemplates, {
    ModerationTemplate,
} from './ModerationTemplates'
import { toastError, toastSuccess } from '../Notification'

type Props = {
    account: AccountRecord
    onTemplateApplied: () => void
    selectedTemplate: ModerationTemplate | undefined
    onSetSelectedTemplate: (selectedTemplate: ModerationTemplate) => void
    classifierDetailsList: ClassifierDetails[]
}

export default function ModerationBoundaryTemplateSelection(props: Props) {
    const {
        account,
        onTemplateApplied,
        selectedTemplate,
        onSetSelectedTemplate,
        classifierDetailsList,
    } = props

    const [showApplyTemplateDialog, setShowApplyTemplateDialog] =
        useState<boolean>(false)

    const [
        bulkUpdateModerationBoundaryMutation,
        { loading: bulkUpdatingModerationBoundary },
    ] = useBulkUpdateModerationBoundaryMutation({
        onError: () => {
            toastError('Failed to bulk update moderation boundaries')
        },
        onCompleted: () => {
            toastSuccess('Moderation boundaries updated.')
        },
    })

    const templates = getModerationTemplates(
        account.socialMediaServiceShortName || ''
    )

    const classifierDetailsMap: Map<string, ClassifierDetails> =
        createClassifierDetailsMap(classifierDetailsList)

    const handleApplyTemplate = async () => {
        const mappedSevereBoundaries = mapTemplateValue(
            account.id,
            classifierDetailsMap,
            selectedTemplate?.severe.values(),
            Queue.SEVERE
        )

        const mappedSuspectBoundaries = mapTemplateValue(
            account.id,
            classifierDetailsMap,
            selectedTemplate?.suspect.values(),
            Queue.SUSPECT
        )

        const mappedSafeBoundaries = mapTemplateValue(
            account.id,
            classifierDetailsMap,
            selectedTemplate?.safe.values(),
            Queue.SAFE
        )

        await bulkUpdateModerationBoundaryMutation({
            variables: {
                accountId: account.id,
                moderationBoundaries: [
                    ...mappedSevereBoundaries,
                    ...mappedSuspectBoundaries,
                    ...mappedSafeBoundaries,
                ],
            },
        })

        setShowApplyTemplateDialog(false)
        onTemplateApplied()
    }

    return (
        <div className="mb-6 flex w-3/4 flex-row items-baseline justify-start content-evenly">
            <div className="pr-4">Compare with </div>
            <Listbox value={selectedTemplate} onChange={onSetSelectedTemplate}>
                <div className="relative mt-1 w-1/2">
                    <ListboxButton className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-primary focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
                        <span className="block truncate">
                            {selectedTemplate?.displayName || 'Select...'}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </ListboxButton>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <ListboxOptions className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <ListboxOption
                                key="none_selected"
                                className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-4 pr-4 ${
                                        active
                                            ? 'bg-amber-100 text-amber-900'
                                            : 'text-gray-950'
                                    }`
                                }
                                value=""
                            >
                                {({ selected }) => (
                                    <>
                                        <span
                                            className={`block truncate pl-6 ${
                                                selected ? '' : 'font-normal'
                                            }`}
                                        >
                                            None
                                        </span>
                                        {selected ? (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        ) : null}
                                    </>
                                )}
                            </ListboxOption>
                            {templates.map((template, index) => (
                                <ListboxOption
                                    key={index}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-4 pr-4 ${
                                            active
                                                ? 'bg-amber-100 text-amber-900'
                                                : 'text-gray-950'
                                        }`
                                    }
                                    value={template}
                                >
                                    {({ selected }) => (
                                        <>
                                            <span
                                                className={`block truncate pl-6 ${
                                                    selected
                                                        ? ''
                                                        : 'font-normal'
                                                }`}
                                            >
                                                {template.displayName}
                                            </span>
                                            {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                    <CheckIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </ListboxOption>
                            ))}
                        </ListboxOptions>
                    </Transition>
                </div>
            </Listbox>
            <div className="pl-4">
                <button
                    className="text-primary-text hover:text-primary-text bg-primary-600 p-2 rounded inline-flex items-center"
                    onClick={() => setShowApplyTemplateDialog(true)}
                >
                    <span>Apply template</span>
                </button>
            </div>

            <SimpleModal
                open={showApplyTemplateDialog}
                onClose={() => {
                    setShowApplyTemplateDialog(false)
                }}
            >
                <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <DialogTitle
                        as="h3"
                        className="text-lg leading-6  text-gray-950"
                    >
                        Are you sure?
                    </DialogTitle>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            Applying this template will change all existing
                            moderation boundaries to those shown
                        </p>
                    </div>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            You cannot undo this action.
                        </p>
                    </div>
                    <div className="flex flex-row">
                        <div className="mt-4">
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-primary-100 px-4 py-2 text-sm  text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2"
                                onClick={handleApplyTemplate}
                            >
                                Confirm and apply changes
                            </button>
                        </div>

                        {bulkUpdatingModerationBoundary ? (
                            <div className="ml-6 mt-4">
                                <SimpleLoader loading={true} />
                            </div>
                        ) : null}
                    </div>
                </DialogPanel>
            </SimpleModal>
        </div>
    )
}
