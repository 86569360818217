import {
    ModerationBoundaryView,
    useDeleteModerationBoundaryMutation,
} from '../../api/client'
import { ModerationBoundaryTemplate } from '../../models/Moderation'
import { toastError, toastSuccess } from '../Notification'
import SimpleLoader from '../SimpleLoader'

type Props = {
    boundary: ModerationBoundaryView
    onEditClick: (boundary: ModerationBoundaryView) => void
    showCompare: boolean
    compareWith?: ModerationBoundaryTemplate
    onDelete: (moderationBoundaryView: ModerationBoundaryView) => void
}
function ModerationBoundaryRow(props: Props) {
    const { boundary, onEditClick, showCompare, compareWith, onDelete } = props
    const [
        deleteModerationBoundary,
        { loading: deleteModerationBoundaryLoading },
    ] = useDeleteModerationBoundaryMutation({
        onError: () => {
            toastError('Failed to delete moderation boundary')
        },
        onCompleted: () => {
            toastSuccess('Moderation boundary deleted')
        },
    })

    const sameBgClassName = 'bg-green-100'
    const differentBgClassName = 'bg-amber-100'

    let compareWithClassName = []
    if (showCompare) {
        if (compareWith?.probability && boundary.probability) {
            if (compareWith?.probability === boundary.probability) {
                compareWithClassName.push(sameBgClassName)
            } else {
                compareWithClassName.push(differentBgClassName)
            }
        } else if (compareWith?.severityCategory && boundary.severityCategory) {
            if (compareWith?.severityCategory === boundary.severityCategory) {
                compareWithClassName.push(sameBgClassName)
            } else {
                compareWithClassName.push(differentBgClassName)
            }
        }

        if (!!!compareWith) {
            // It is not defined in the template - this field will be deleted
            compareWithClassName.push(differentBgClassName)
        }
    }
    const compareWithDisplay = compareWith ? (
        <div className={compareWithClassName.join(' ')}>
            {compareWith.probability
                ? compareWith.probability
                : compareWith.severityCategory}
        </div>
    ) : null

    return (
        <tr key={boundary.id} className={compareWithClassName.join(' ')}>
            <td className="whitespace-nowrap px-2 py-2 text-sm  text-gray-950">
                {boundary.classifier.id}
            </td>
            <td className="whitespace-nowrap px-2 py-2 text-sm  text-gray-950">
                <span className="">{boundary.classifier.displayName}</span>{' '}
                <span className="text-gray-700 ml-3">
                    ({boundary.classifier.shortName})
                </span>
            </td>
            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-950">
                {boundary.severityCategory}
            </td>
            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-950">
                {boundary.probability}
            </td>
            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-950">
                {compareWithDisplay}
            </td>
            <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm  sm:pr-6">
                <div className="flex flex-row ">
                    <button
                        onClick={() => {
                            onEditClick(boundary)
                        }}
                        className="text-primary hover:text-primary-900 mr-3"
                    >
                        Edit
                    </button>
                    {!deleteModerationBoundaryLoading && (
                        <button
                            onClick={async () => {
                                await deleteModerationBoundary({
                                    variables: {
                                        moderationBoundaryId: boundary.id,
                                    },
                                })
                                onDelete(boundary)
                            }}
                            className="text-severe hover:text-primary-900 "
                            disabled={deleteModerationBoundaryLoading}
                        >
                            Delete
                        </button>
                    )}
                    <SimpleLoader loading={deleteModerationBoundaryLoading} />
                </div>
            </td>
        </tr>
    )
}

export default ModerationBoundaryRow
