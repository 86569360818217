import { Fragment, useState } from 'react'
import type { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import Toggle from '../../components/toggles/Toggle'
import SimpleLoader from '../../components/SimpleLoader'
import { useForm, SubmitHandler } from 'react-hook-form'
import {
    useCreateTiktokAccountsMutation,
    CreateTiktokAccountsMutation,
} from '../../api/client'

type FormValues = {
    toggles: Record<string, boolean>
}

type Props = {
    adAccounts: Array<{
        advertiserId: string
        advertiserName: string
    }>
    sessionKey: string
}

const AdAccountsSelectionPage = ({ adAccounts, sessionKey }: Props) => {
    const navigate = useNavigate()
    const {
        handleSubmit,
        setValue,
        watch,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm<FormValues>({
        defaultValues: {
            toggles: {},
        },
    })
    const toggles = watch('toggles')

    const [authorisedAccount, setAuthorisedAccount] =
        useState<CreateTiktokAccountsMutation | null>(null)
    const [сreateTiktokAccountsMutation, { loading: accountsLoading }] =
        useCreateTiktokAccountsMutation({
            onCompleted: function (data) {
                setAuthorisedAccount(data)
            },
        })

    const onSubmit: SubmitHandler<FormValues> = (data) => {
        const adAccountIds = adAccounts
            .filter((item) => data.toggles[item.advertiserId])
            .map((item) => item.advertiserId)
        if (adAccountIds.length) {
            сreateTiktokAccountsMutation({
                variables: {
                    adAccountIds,
                    sessionKey,
                },
            })
        } else {
            setError('toggles', {
                type: 'custom',
                message: 'Select at least one ad account',
            })
        }
    }

    const handleToggle = (id: string, isEnabled: boolean) => {
        setValue(`toggles.${id}`, isEnabled, { shouldValidate: true })
        clearErrors('toggles')
    }

    const onToggle = async (advertiserId: string, isEnabled: boolean) => {
        await handleToggle(advertiserId, isEnabled)
    }

    if (authorisedAccount?.createTiktokAccounts.length) {
        navigate('/account/new/authorised', {
            state: {
                authorisedAccounts: authorisedAccount.createTiktokAccounts,
            },
        })
    }

    return (
        <div className="m-10 bg-white mx-auto w-1/2 max-w-7xl py-16 px-4 sm:px-6 lg:pt-6 lg:pb-12 lg:px-16 space-y-8 divide-y sm:space-y-5 prose">
            <div className="flex flex-col justify-center">
                <div className="p-4">
                    <h2>Please select one or more Ad accounts</h2>
                    <p>Arwen will create a new profile for each Ad account.</p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {adAccounts.map((account) => (
                            <Fragment key={account.advertiserId}>
                                <Toggle
                                    loading={accountsLoading}
                                    checked={
                                        toggles
                                            ? toggles[account.advertiserId]
                                            : false
                                    }
                                    onToggle={(isEnabled) =>
                                        onToggle(
                                            account.advertiserId,
                                            isEnabled
                                        )
                                    }
                                    label={account.advertiserName}
                                    tooltip={account.advertiserName}
                                    disabled={false}
                                    id={account.advertiserId}
                                    size="medium"
                                />
                            </Fragment>
                        ))}
                        {errors.toggles && (
                            <p className="text-base my-0 ml-3 text-red-700">
                                {errors.toggles?.message as ReactNode}
                            </p>
                        )}
                        <div className="items-center flex flex-row mt-4">
                            {accountsLoading ? (
                                <div className="ml-6 mt-4">
                                    <SimpleLoader loading={true} />
                                </div>
                            ) : (
                                <>
                                    <button
                                        className="text-base inline-flex items-center px-3 py-2 border border-transparent leading-4  rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 shadow-sm text-white bg-primary hover:bg-primary-700 cursor-pointer"
                                        type="submit"
                                        disabled={accountsLoading}
                                    >
                                        Link account(s)
                                    </button>
                                    <button
                                        type="reset"
                                        onClick={() => {
                                            navigate(-1)
                                        }}
                                        className="py-2 px-6 underline"
                                    >
                                        Cancel
                                    </button>
                                </>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AdAccountsSelectionPage
