import { useState } from 'react'
import { useRecoilState } from 'recoil'
import { ChartBarIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid'

import { toastError } from '../../components/Notification'
import { isUserAdminState } from '../../store/DomainStore'
import SimpleLoader from '../../components/SimpleLoader'
import {
    useDeleteDashboardMutation,
    useGetDashboardsQuery,
} from '../../api/client'

export default function ReportListPage() {
    const [admin] = useRecoilState(isUserAdminState)
    const navigate = useNavigate()

    const { data, loading, refetch } = useGetDashboardsQuery({
        fetchPolicy: 'network-only',
        onError: (error) => {
            console.error('There was an error fetching user dashboards', error)
        },
    })

    const [deleteDashboardMutation] = useDeleteDashboardMutation({
        onError: (error) => {
            toastError(error.message)
        },
    })

    // State to track which dashboard is being deleted
    const [deletingDashboardId, setDeletingDashboardId] =
        useState<number | null>(null)

    const handleDelete = async (dashboardId: number) => {
        setDeletingDashboardId(dashboardId)
        try {
            await deleteDashboardMutation({
                variables: { dashboardId },
            })
            await refetch()
        } finally {
            setDeletingDashboardId(null)
        }
    }

    const noReports = !loading && data?.getDashboards.length === 0

    return (
        <div className="w-full max-w-7xl mx-auto p-6 space-y-6">
            {/* Header */}
            <div className="flex flex-wrap items-baseline justify-between">
                <h2 className="text-2xl font-bold">Your reports</h2>
                {admin && (
                    <a
                        href="#/report/new"
                        className="text-primary-600 underline hover:text-primary-800 text-md"
                    >
                        Create a new report
                    </a>
                )}
            </div>

            {/* Report list */}
            {!noReports && (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {data?.getDashboards.map((dashboard) => (
                        <div
                            key={dashboard.id}
                            className="p-6 bg-white shadow-md rounded-lg border border-gray-200 hover:shadow-lg transition-shadow cursor-pointer"
                            onClick={() => navigate(`/report/${dashboard.id}`)}
                        >
                            {/* Report header */}
                            <div className="flex justify-between items-start">
                                <h3 className="text-lg font-medium text-gray-900">
                                    {dashboard.displayName}
                                </h3>
                                {admin && (
                                    <div className="flex space-x-2">
                                        <PencilIcon
                                            className="h-5 w-5 text-primary-500 hover:text-primary-700 cursor-pointer flex-shrink-0"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                navigate(
                                                    `/report/${dashboard.id}/edit`
                                                )
                                            }}
                                        />
                                        {/* Display a spinner if we're currently deleting a dashboard. */}
                                        {deletingDashboardId ===
                                        dashboard.id ? (
                                            <div className="h-5 w-5 border-2 border-gray-300 border-t-primary-500 rounded-full animate-spin"></div>
                                        ) : (
                                            <TrashIcon
                                                className="h-5 w-5 text-primary-500 hover:text-primary-700 cursor-pointer flex-shrink-0"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    handleDelete(dashboard.id)
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>

                            {/* Report details */}
                            <p className="mt-2 text-gray-700">
                                {dashboard.description}
                            </p>
                            {admin && (
                                <p className="mt-2 text-sm text-gray-500">
                                    Dashboard ID:{' '}
                                    <span className="font-mono text-red-500">
                                        {dashboard.extEmbeddedId}
                                    </span>
                                </p>
                            )}
                        </div>
                    ))}
                </div>
            )}

            {/* Support info */}
            {noReports ? (
                <div className="text-center text-gray-700 space-y-4">
                    <div className="flex justify-center mb-4">
                        <ChartBarIcon className="w-20 h-20 text-gray-900" />
                    </div>
                    <p>
                        You don't yet have any reports. Please contact us on{' '}
                        <a
                            href="mailto:support@arwen.ai"
                            className="text-primary-600 underline hover:text-primary-800"
                        >
                            support@arwen.ai
                        </a>{' '}
                        to get one set up.
                    </p>
                </div>
            ) : (
                <p className="text-center text-gray-700">
                    We would be happy to help you with additional reports.
                    Please contact us on{' '}
                    <a
                        href="mailto:support@arwen.ai"
                        className="text-primary-600 underline hover:text-primary-800"
                    >
                        support@arwen.ai
                    </a>
                    .
                </p>
            )}

            {loading && <SimpleLoader loading={loading} />}
        </div>
    )
}
