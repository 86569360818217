/**
 * These components are used to set the title of the page.
 *
 * The TitleProvider needs to wrap the app the same as any other provider,
 * and you can use the `useTitle` hook to set the title of the page.
 *
 * You should probably only use this in the `pages` directory. If you start
 * using it in components you might end up with components fighting over it and
 * I don't know what'll happen then: probably the on that mounts last will win,
 * but the upshot will be unpredictable page titles.
 */
import {
    createContext,
    useContext,
    useState,
    useEffect,
    ReactNode,
    FC,
} from 'react'

const defaultTitle = 'Arwen.AI'

interface TitleContextType {
    title: string
    setTitle: (title: string) => void
}

const defaultState: TitleContextType = {
    title: defaultTitle,
    setTitle: () => {},
}

export const TitleContext = createContext<TitleContextType>(defaultState)

/**
 * Use this hook to set the title of the page. It'll revert to the default
 * title when you navigate away from the page/the component unmounts.
 */
export const useTitle = (title: string): void => {
    const { setTitle } = useContext(TitleContext)

    useEffect(() => {
        setTitle(title)

        // We need to reset the title when the component unmounts
        return () => {
            setTitle(defaultState.title)
        }
    }, [title, setTitle])
}

interface TitleProviderProps {
    children: ReactNode
}

/**
 * The provider that wraps the app and provides the title context.
 */
export const TitleProvider: FC<TitleProviderProps> = ({ children }) => {
    const [title, setTitle] = useState<string>(defaultTitle)

    useEffect(() => {
        document.title = title
    }, [title])

    return (
        <TitleContext.Provider value={{ title, setTitle }}>
            {children}
        </TitleContext.Provider>
    )
}
