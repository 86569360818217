import cloneDeep from 'lodash/cloneDeep'
import { ModerationBoundaryView } from '../../api/client'
import { ModerationBoundaryTemplate } from '../../models/Moderation'
import ModerationBoundaryRow from './ModerationBoundaryRow'

type Props = {
    boundaries: ModerationBoundaryView[]
    setSelectedBoundary: (boundary: ModerationBoundaryView) => void
    setEditOpen: (open: boolean) => void
    headerClassNames?: string
    showCompare: boolean
    compareWith: Map<string, ModerationBoundaryTemplate>
    compareName: string
    onDelete: (moderationBoundaryView: ModerationBoundaryView) => void
}
function ModerationBoundaryList(props: Props) {
    const {
        boundaries,
        setSelectedBoundary,
        setEditOpen,
        showCompare,
        compareWith,
        headerClassNames,
        compareName,
        onDelete,
    } = props

    return (
        <div className="flex flex-col">
            <div className="inline-block min-w-full align-middle ">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className={headerClassNames}>
                        <tr>
                            <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white"
                            >
                                Classifier id
                            </th>
                            <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white"
                            >
                                Classifier
                            </th>
                            <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white"
                            >
                                Severity
                            </th>
                            <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white"
                            >
                                Probability
                            </th>
                            <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white"
                            >
                                {compareName}
                            </th>
                            <th
                                scope="col"
                                className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                                <span className="sr-only">Edit</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {boundaries.map((boundary) => {
                            const matchingBoundaryTemplate = compareWith.get(
                                boundary.classifier.shortName.toLowerCase()
                            )

                            return (
                                <ModerationBoundaryRow
                                    key={boundary.classifier.id}
                                    boundary={boundary}
                                    onEditClick={() => {
                                        setSelectedBoundary(cloneDeep(boundary))
                                        setEditOpen(true)
                                    }}
                                    showCompare={showCompare}
                                    compareWith={matchingBoundaryTemplate}
                                    onDelete={onDelete}
                                />
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ModerationBoundaryList
