import { NewAccountButtons } from '../../components/AddAccountDropDown'
import { TextPageContainer } from '../../containers/TextPageContainer'
import { TextPageHeader } from '../../containers/TextPageHeader'

/**
 * Shows a list of buttons to add new accounts.
 */
function NewAccountPage() {
    return (
        <TextPageContainer>
            <TextPageHeader title="Add a profile to Arwen" />
            <NewAccountButtons />
        </TextPageContainer>
    )
}

export default NewAccountPage
