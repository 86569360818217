/**
 * This page is used to confirm that we've authorised an account.
 */
import { Link, useLocation } from 'react-router-dom'
import pluralize from 'pluralize'
import { TextPageContainer } from '../../containers/TextPageContainer'
import { TextPageHeader } from '../../containers/TextPageHeader'
import AccountSummary from '../../components/account/AccountSummary'
import { AuthorisedAccountView } from '../../api/client'

type LocationState = {
    authorisedAccounts?: Array<AuthorisedAccountView>
}

function NewAccountAuthorisedPage() {
    const location = useLocation()
    const state = location.state as LocationState

    // SOMEDAY: get the new and updated accounts details from the query params
    // and display them here.
    return (
        <TextPageContainer>
            <TextPageHeader title="Success!" />
            {state?.authorisedAccounts ? (
                <>
                    <p>
                        Your{' '}
                        {pluralize('account', state.authorisedAccounts.length)}{' '}
                        {pluralize('has', state.authorisedAccounts.length)}
                        been added to Arwen.
                    </p>
                    <ul className="list-none">
                        {state.authorisedAccounts.map((account) => (
                            <li key={account.id}>
                                <AccountSummary {...account} />
                            </li>
                        ))}
                    </ul>{' '}
                </>
            ) : (
                <p>Your account has been added to Arwen.</p>
            )}
            <p>
                We'll be in touch via email as soon as Arwen is live with your
                chosen settings.
            </p>
            <Link
                className={`text-primary-600 p-2 underline `}
                to="/account/new"
            >
                Add another profile
            </Link>
        </TextPageContainer>
    )
}

export default NewAccountAuthorisedPage
